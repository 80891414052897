import logo from '../../../assets/logo.png';
import {Button, Form, Input} from "antd";
import './ForgotPassword.css';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LOGIN} from "../../../constants/RoutePathConstants";
import {useForgotPassword} from "./hooks/useForgotPassword";
import {AyrmeshButton} from "../../../shared-components/ayrmesh-button/AyrmeshButton";


export function ForgotPassword() {
    const { submit, isLoading, isSuccess } = useForgotPassword();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const navigateToLogin = () => {
        navigate(LOGIN);
    }


    return (
        <div className="container">
            <img width="300px" src={logo} alt="Ayrstone logo" />
            <h3 style={{marginTop: 10, marginBottom: 10}}>Recover your password</h3>
            {!isSuccess ?
                <>
                    <p style={{marginTop: 10, marginBottom: 20}}>Enter your username or email and we’ll send you a reset link on your email.</p>
                    <Form
                        form={form}
                        layout="vertical"
                        name="basic"
                        onFinish={submit}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{marginBottom: 5}}
                            label="Username or Email"
                            name="username"
                            rules={[{required: true, message: 'Please input your username or email!'}]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item style={{marginBottom: 10, marginTop: 20}}>
                            <AyrmeshButton loading={isLoading} block type="primary" htmlType="submit">
                                Send recovery link
                            </AyrmeshButton>
                        </Form.Item>
                    </Form>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={() => navigateToLogin()} style={{paddingLeft: 0}} type="link">Sign in</Button>
                    </div>
                </> :
                <p style={{marginTop: 10, marginBottom: 20}}>An email has been sent. Please click the link when you get it.</p>
            }
        </div>
    )
}

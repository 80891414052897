import {Alert, Button, Form, Input, Modal, notification} from "antd";
import {useEffect} from "react";
import {Device} from "../../../../../../models/Device";
import {useAddDevice} from "./hooks/useAddDevice";

type AddDeviceProps = {
    isModalVisible: boolean;
    setIsModalVisible: any;
    networkId: number;
}

export function AddDevice({networkId, isModalVisible, setIsModalVisible }: AddDeviceProps) {
    const {submitAddDevice, isSuccess, isLoading, isError, error } = useAddDevice();
    const [form] = Form.useForm();

    let validationError;
    const potentialError = error as any;
    if (potentialError?.response?.status === 422) {
        validationError = potentialError?.response?.data;
    }

    const handleOk = () => {
        form.submit();
    }

    const handleSubmit = (data: any) => {
        const device = data as Device;
        device.network_id = networkId;
        submitAddDevice(device);
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    }
    const formItems = [
        {
            name: "name",
            label: "Name",
            value: '',
        },
        {
            name: "MAC",
            label: "MAC Address",
            value: '',
        },
    ];

    useEffect(() => {
        if (isSuccess) {
            setIsModalVisible(false);
            notification.success({
                message: "You've successfully added a device to the network"
            });
        }
    }, [isSuccess])

    return (
        <Modal
            title="Add Device to Network"
            open={isModalVisible}
            okButtonProps={{
                loading: isLoading,
                style: { background: '#05a955', borderColor: '#05a955' }
            }}
            bodyStyle={{
                height: "40vh",
                overflowY: "auto"
            }}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {isError && !validationError && (error as any) && (
                <Alert
                    style={{marginBottom: 10}}
                    message={(error as any)?.response?.data?.detail || "An error occurred"}
                    type="error"
                />
            )}
            {isError && validationError && (validationError.detail.map((e: any, idx: number) =>
                        <Alert
                            key={idx}
                            style={{marginBottom: 10}}
                            type="error"
                            message={
                                <>
                <span style={{fontWeight: "bold"}}>
                  {e.loc[1]}:
                </span> {" "} {e.msg}
                                </>
                            }
                        />
                )
            )}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                {formItems.map((fi, idx) =>
                    <Form.Item
                        initialValue={fi.value}
                        name={fi.name}
                        label={fi.label}
                        key={idx}
                        rules={[{ required: true, message: 'Please input the ' + fi.label }]}
                    >
                        <Input />
                    </Form.Item>
                )}
                <Form.Item style={{display: "none"}}>
                    <Button htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

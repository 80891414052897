import logo from '../../../assets/logo.png';
import './Login.css';
import {useAppDispatch} from "../../../redux/hooks";
import {useEffect} from "react";
import {login} from "../../../redux/slices/authSlice";
import {useLogin} from "./hooks/useLogin";
import {Alert, Button} from "antd";
import {LoginForm} from "./components/LoginForm";
import {useNavigate, useLocation} from "react-router-dom";
import {FORGOT_PASSWORD, LOGIN, REGISTER} from "../../../constants/RoutePathConstants";
import {AfterRegister} from "./components/after-register/AfterRegister";


export function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    submitLogin,
    isLoading,
    isSuccess,
    data,
    isError,
    error
  } = useLogin();

  const handleSubmitLogin = (data: any) => {
    navigate(LOGIN, { state: {}});
    submitLogin(data);
  }

  const navigateToRegister = () => {
    navigate(REGISTER);
  }

  const navigateToForgotPassword = () => {
    navigate(FORGOT_PASSWORD);
  }

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(login(data));
    }
  }, [isSuccess, data])

  return (
    <div className="login-container">
      <img width="300px" src={logo} alt="Ayrstone logo" />
      {isError && (
        <Alert style={{marginTop: 20, width: 300}} message={(error as any)?.response?.data?.detail} type="error" />
      )}
      <AfterRegister />
      <LoginForm isLoading={isLoading} handleSubmit={handleSubmitLogin} />
      <p><Button onClick={() => navigateToForgotPassword()} style={{paddingLeft: 0}} type="link">Forgot password ?</Button></p>
      <p>No account? <Button onClick={() => navigateToRegister()} style={{paddingLeft: 0}} type="link">Sign up here</Button></p>
    </div>
  )
}
